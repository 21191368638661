import { LocalstorageService } from './../../services/localstorage.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { CareersComponent } from '../careers-cluster/careers/careers.component';
@Injectable({
  providedIn: 'root'
})

export class AdminService {
  private dataSource = new BehaviorSubject<Object>(0);
  data = this.dataSource.asObservable();
  public s3Image = '';

  private simpleDataSource = new BehaviorSubject<number>(0);
  simpleTableData = this.simpleDataSource.asObservable();

  public onetimecaller = new BehaviorSubject<Boolean>(true);
  onceCalled = this.onetimecaller.asObservable();

  private userType = new BehaviorSubject<any>('Viewer');
  usertype = this.userType.asObservable();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': '',
    })
  };

  SharingData = new Subject();
  private subject = new Subject<any>();
  private simpleSubject = new Subject<any>();

  private apiUrl: string;
  private apiUrlForSettings: string;
  private apiUrlForStud: string;
  private apiURLForLeadsquare: string;
  mobileViewBreakPointObs: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  navbarData = [
    { title: "Dashboard", routerLink: '/admin/MainDashboard', icon: 'parents', active: false },
    {
      title: "Users", routerLink: '', icon: 'student-nav', active: false, opened: false,
      children: [
        { title: "Student", routerLink: '/admin/user/student-list', icon: 'sub-menu', active: false },
        { title: "Parent", routerLink: '/admin/user/parent-list', icon: 'sub-menu', active: false },
      ]
    },
    {
      title: "Settings", routerLink: '', icon: 'settings', active: false, opened: false,
      children: [
        { title: "Admin", routerLink: '/admin/user/admin-list', icon: 'sub-menu', active: false },
      ]
    },
    {
      title: "Activity", routerLink: '', icon: 'activity-regular', active: false, opened: false,
      children: [
        { title: "Import", routerLink: '/admin/user/import-list', icon: 'sub-menu', active: false },
      ]
    },
    { title: "Feedback", routerLink: '/admin/feedback', icon: 'feedback', active: false },

  ]

  backupNavbarData = [
    { title: "Dashboard", routerLink: '/admin/MainDashboard', icon: 'parents', active: false },
    {
      title: "Users", routerLink: '', icon: 'student-nav', active: false, opened: false,
      children: [
        { title: "Student", routerLink: '/admin/user/student-list', icon: 'sub-menu', active: false },
        { title: "Parent", routerLink: '/admin/user/parent-list', icon: 'sub-menu', active: false },
      ]
    },
    { title: "Feedback", routerLink: '/admin/feedback', icon: 'feedback', active: false },

  ]
  constructor(private _snackBar: MatSnackBar,
    private http: HttpClient,
    private _authService: AuthService,
    private _localStorage: LocalstorageService) {
    const BASE_API_URL = environment.ApiConfig.admin;
    const BASE_SETTING_URL = environment.ApiConfig.settings;
    const BASE_LEADSQUARE_URL = environment.ApiConfig.leadsquared;
    this.apiUrl = `${BASE_API_URL}/admin`;
    this.apiUrlForStud = `${BASE_API_URL}`;
    this.apiUrlForSettings = `${BASE_SETTING_URL}`;
    this.apiURLForLeadsquare = `${BASE_LEADSQUARE_URL}`;

    this._authService.getUserDetails().then(data => {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': data.user.signInUserSession.idToken.jwtToken,
        })

      };
      //this.http.options(this.httpOptions);

      localStorage.setItem('token', data.user.signInUserSession.idToken.jwtToken);
    })
  }

  publishMobileView(value): void {
    this.mobileViewBreakPointObs.next(value);
  }

  getCustomersLarge() {
    return this.http.get<any>('assets/customers-large.json')
      .toPromise()
      .then(res => <CareersComponent[]>res.data)
      .then(data => { return data; });
  }
  highlightLeftMenu(url, type) {
    var tempData1 = [];
    var tempData = [];
    tempData1 = JSON.parse(JSON.stringify(this.navbarData));
    tempData = tempData1;
    if (type == 'child') {
      tempData.forEach((element, index) => {
        if (element.children && element.children.length > 0) {
          element.children.forEach((child) => {
            if (child.routerLink == url) {
              element.active = true;
              element.opened = true;
              child.active = true;
            }
          });
        }
      })
    }
    if (type == 'parent') {
      tempData.forEach((element1, index) => {
        if (element1.children && element1.children.length > 0) {
          element1.children.forEach(child => {
            child.active = false;
            element1.opened = false;
            element1.active = false;
          });
        }
        if (element1.routerLink == url) {
          element1.active = true
        }
      })
    }
    let cloneData: any = [];
    cloneData = JSON.parse(JSON.stringify(tempData));
    this.navbarData = cloneData;
    this._localStorage.set('admin_sidebarData', this.navbarData);
  }

  getCurrentDate() {
    return moment().format("YYYY-MM-DD HH:mm:ss");

  }
  sendMessage(message: any) {
    this.subject.next({ text: message });
  }

  sendSimpleMessage(message: any) {
    this.simpleSubject.next({ text: message })
  }

  clearMessages() {
    this.subject.next();
  }

  clearSimpleMessages() {
    this.simpleSubject.next();
  }

  // getExportDataForTable(){
  //   return this.http.get(`${this.apiUrl}/student/basic?export=true`);
  // }

  getExportDataForTable(data) {
    console.log('data----------------------', data)
    data.entity_id = environment.entity_id
    return this.http.post(`${this.apiUrl}/student/basic/filters`, data, this.httpOptions);
  }

  updateLicense(updatedData) {
    return this.http.patch(`${this.apiUrl}/entity/licenses`, updatedData, this.httpOptions);
  }

  onMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  onSimpleMessage(): Observable<any> {
    return this.simpleSubject.asObservable();
  }

  updateCareerQuizDetails(updatedData) {
    return this.http.patch(`${this.apiUrl}/student/career/quiz`, updatedData, this.httpOptions);
  }

  setData(data) {
    this.dataSource.next(data);
  }

  setSimpleTableData(data) {
    this.simpleDataSource.next(data);
  }
  setUserType(data) {
    this.userType.next(data);
  }

  showMessage() {
    this._snackBar.open('Changes Saved!', 'x', {
      duration: 3 * 1000,
      verticalPosition: 'bottom',
      panelClass: ['success-snackbar']
    });
  }

  showMessageForDelete() {
    this._snackBar.open('Deleted Successfully!', 'x', {
      duration: 3 * 1000,
      verticalPosition: 'bottom',
      panelClass: ['success-snackbar']
    });
  }

  showMessageForDeleteFalse() {
    this._snackBar.open('Delete unsuccessful!', 'x', {
      duration: 3 * 1000,
      verticalPosition: 'bottom',
      panelClass: ['error-snackbar']
    });
  }

  showMessageForAdmin(massage, colorClass) {
    this._snackBar.open(massage, 'x', {
      duration: 3 * 1000,
      verticalPosition: 'bottom',
      panelClass: [colorClass]
    });
  }

  //GET
  getStudentFilterDetails(request) {
    return this.http.get(`${this.apiUrl}/student/basic/data?startDate=${request.startDate}&endDate=${request.endDate}`, this.httpOptions);
  }

  //GET
  getSampleCSV() {
    return this.http.get(`${this.apiUrl}/import/header`, this.httpOptions);
  }

  fetchEntityDropdown() {
    let entity_id = environment.entity_id
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      })

    };
    return this.http.get(`${this.apiUrl}/entity?id=${entity_id}`, this.httpOptions);
  }


  fetchImportData() {
    // fetching partner specific list 28062023
    let entity_id = environment.entity_id
    return this.http.get(`${this.apiUrl}/import/stats?entity_id=${entity_id}`, this.httpOptions);
  }

  fetchPartneDataByFilter(oQueryData) {
    let url = this.apiUrl + "/partner/filter";
    return this.http.post<any>(url, oQueryData, this.httpOptions);
  }

  // modified the API to load data 10 by 10 on 10022023
  fetchPartnerData(skip = 0, limit = 10, orderBy?: string, orderByType?: string) {
    let url = `${this.apiUrl}/entity/count?skip=${skip}&limit=${limit}`;
    if (orderBy && orderByType) {
      url = url + '&orderBy=' + orderBy + '&orderByType=' + orderByType;
    }
    return this.http.get(url, this.httpOptions);
  }

  // modified the API to load data 10 by 10 on 10022023
  fetchLicenseData(id, total) {
    return this.http.get(`${this.apiUrl}/entity/count?total=${total}&id=${id}`, this.httpOptions);
  }

  updateImportData(postData) {
    return this.http.post(`${this.apiUrl}/import/stats`, postData, this.httpOptions);
  }

  getValidcognitoUser(postData) {
    return this.http.post(`${this.apiUrlForSettings}/signup/users`, postData, this.httpOptions);
  }
  getValidLeadSquareUser(payload) {
    return this.http.post(`${this.apiURLForLeadsquare}/leadsquare/leads?` + payload.field + `=` + payload.value, this.httpOptions);
  }

  fetchAssessmentCompletedList(test) {
    let entity_id = environment.entity_id

    return this.http.get(`${this.apiUrl}/dashboard/assessment/complete?type=${test}&entity_id=${entity_id}`, this.httpOptions);
  }
  createJobId(postData) {
    return this.http.post(`${this.apiUrl}/import/job`, postData, this.httpOptions);
  }
  //GET
  getStudentDetails() {
    return this.http.get(`${this.apiUrl}/student/basic/data`, this.httpOptions);
  }

  getStudentDetailsUsingPagination(limt, skip) {
    return this.http.get(`${this.apiUrl}/student/basic/data?limit=${limt}&skip=${skip}`, this.httpOptions);
  }

  getSortedData(limt, skip, sortBy, sortType) {
    if (sortBy == 'plan' || sortBy == 'expire_date') {
      sortBy = "ssp." + sortBy
    }
    else if (sortBy == 'persued_on' || sortBy == 'currentClass') {
      sortBy = "se." + sortBy
    }
    else {
      sortBy = "sb." + sortBy
    }
    return this.http.get(`${this.apiUrl}/student/basic?limit=${limt}&skip=${skip}&orderBy=${sortBy}&orderByType=${sortType}`, this.httpOptions);
  }


  //PATCH
  updateStudentDetails(uuid, updatedData) {
    delete updatedData.full_name;
    return this.http.patch(`${this.apiUrl}/student/basic/${uuid}`, updatedData, this.httpOptions);
  }

  deleteStudentDetails(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': '',
      }),
      body: data
    };
    return this.http.delete(`${this.apiUrl}/student/basic`, httpOptions);
  }

  deleteParentListDetails(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': '',
      }),
      body: data
    };
    return this.http.delete(`${this.apiUrl}/parent/basic`, httpOptions);
  }

  getStudentSortedData(limit, skip, sortBy: any = false, sortType: any = false, filter: any = false, search: string = '', selectedColumns, exportData: string = 'false', selectedUsers = false) {
    selectedColumns.push('uuid')
    let postData: any = {
      limit: limit,
      skip: skip,
      entity_id: environment.entity_id,
      filter: [],
      searchString: '',
      export: exportData,
      selectedColumns: selectedColumns
    }
    if (selectedUsers) {
      postData.selectedUsers = selectedUsers
    }
    if (filter && filter.length != 0) {
      postData.filter = filter
    }
    else {
      postData.filter = []
    }
    if (sortBy) {
      postData.orderBy = sortBy
    }
    if (search) {
      postData.searchString = search
    }
    if (sortType) {
      postData.orderByType = sortType
    }
    return this.http.post(`${this.apiUrl}/student/basic/data`, postData, this.httpOptions);
  }

  getParentSortedData(limit, skip, sortBy: any = false, sortType: any = false, filter: any = false, search: string = '', selectedColumns, exportData: string = 'false', selectedUsers = false) {
    selectedColumns.push('uuid')
    let postData: any = {
      limit: limit,
      skip: skip,
      entity_id: environment.entity_id,
      filter: [],
      searchString: '',
      export: exportData,
      selectedColumns: selectedColumns
    }
    if (selectedUsers) {
      postData.selectedUsers = selectedUsers
    }
    if (filter && filter.length != 0) {
      postData.filter = filter
    }
    else {
      postData.filter = []
    }
    if (sortBy) {
      postData.orderBy = sortBy
    }
    if (search) {
      postData.searchString = search
    }
    if (sortType) {
      postData.orderByType = sortType
    }
    return this.http.post(`${this.apiUrl}/parent/basic/data`, postData, this.httpOptions);
  }

  updateParentDetails(uuid, updatedData) {
    return this.http.patch(`${this.apiUrl}/parent/${uuid}`, updatedData, this.httpOptions);
  }
  updateEntityShowDialog(updatedData) {
    return this.http.patch(`${this.apiUrl}/entity`, updatedData, this.httpOptions);
  }
  deleteParentDetails(uuid) {
    return this.http.delete(`${this.apiUrl}/parent/${uuid}`, this.httpOptions);
  }

  //Admin-Feedback APIs
  getFeedbackDetails(limit, skip, sortBy: any = false, sortType: any = false, filter: any = false, search: any = false) {
    let postData: any = {
      limit: limit,
      skip: skip,
      orderBy: sortBy,
      orderByType: sortType,
      entity_id: environment.entity_id
    }
    if (filter && filter.length != 0) {
      postData.filter = filter
    }
    if (search) {
      postData.search = search
    }
    if (sortBy) {
      postData.orderBy = sortBy
    }
    if (sortType) {
      postData.orderByType = sortType
    }
    return this.http.post(`${this.apiUrl}/student/feedback`, postData, this.httpOptions);
  }

  exportFeedbackDetails(limit, skip, sortBy: any = false, sortType: any = false, filter: any = false, search: any = false, selectedUsers = false) {
    let postData: any = {
      limit: limit,
      skip: skip,
      orderBy: sortBy,
      orderByType: sortType,
      entity_id: environment.entity_id,
      export: 'true'
    }
    if (filter && filter.length != 0) {
      postData.filter = filter
    }
    if (selectedUsers) {
      postData.selectedUsers = selectedUsers
    }
    if (search) {
      postData.search = search
    }
    if (sortBy) {
      postData.orderBy = sortBy
    }
    if (sortType) {
      postData.orderByType = sortType
    }
    return this.http.post(`${this.apiUrl}/student/feedback`, postData, this.httpOptions);
  }

  getFeedbackForUUID(uuid) {
    let postData = {
      uuid: uuid,
      onlyFeedback: "true",
      entity_id: environment.entity_id
    }
    return this.http.post(`${this.apiUrl}/student/feedback`, postData, this.httpOptions);
  }

  upgradePlan(uuid, plan) {
    let patchData = {
      plan: plan,
      status: 1
    }
    return this.http.patch(`${this.apiUrl}/student/plan?uuid=${uuid}`, patchData, this.httpOptions);
  }

  updateFeedbackDetails(uuid, updatedData) {
    return this.http.patch(`${this.apiUrl}/feedback/${uuid}`, updatedData, this.httpOptions);
  }

  deleteFeedbackDetails(uuid) {
    return this.http.delete(`${this.apiUrl}/feedback/${uuid}`, this.httpOptions);
  }

  // this for email verification
  veriflyEmailByUser(data) {
    console.log('data-------------', data)

    return this.http.patch(`${this.apiUrl}/userpool/updateuserattributes`, data, this.httpOptions);
  }
  //GET Careere info

  getAdminCareerInfo() {
    return this.http.get(`${this.apiUrl}/student/career/information`, this.httpOptions);
  }

  // modified the API to load data 10 by 10 on 08022023
  getAdminClusterAllData(skip: number, limit: number) {
    console.log("line no. 479", `${this.apiUrl}/student/career?skip=${skip}&limit=${limit}`);
    return this.http.get(`${this.apiUrl}/student/career?skip=${skip}&limit=${limit}`, this.httpOptions);
  }

  // modified the API to load data 10 by 10 on 07022023
  getAdminQuizTableData(skip: number, limit: number) {
    console.log("line no. 485", `${this.apiUrl}/student/career/quiz?skip=${skip}&limit=${limit}`);
    return this.http.get(`${this.apiUrl}/student/career/quiz?skip=${skip}&limit=${limit}`, this.httpOptions);
  }

  getAdminAllClusterForDropdown() {
    return this.http.get(`${this.apiUrl}/student/career/pick-clusters`, this.httpOptions);
  }

  addCareerQuiz(data) {
    // data.created_at =this.getCurrentDate();
    // data.updated_at =this.getCurrentDate();
    return this.http.post(`${this.apiUrl}/student/career/add-data`, data, this.httpOptions);
  }

  sendEmail(data) {
    return this.http.post(`${this.apiUrl}/dashboard/contact`, data, this.httpOptions);
  }
  getAdminCareerForDropdown(cluster_id) {
    return this.http.get(`${this.apiUrl}/student/career/pick-careers?cluster_id=${cluster_id}`, this.httpOptions);
  }
  //POST
  addCareerInfo(data) {
    data.created_at = this.getCurrentDate();
    data.updated_at = this.getCurrentDate();
    return this.http.post(`${this.apiUrl}/student/career/information`, data, this.httpOptions);
  }

  //PATCH
  updateCareerInfo(uuid, data) {
    data.updated_at = this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/student/career/information/${uuid}`, data, this.httpOptions);
  }


  deleteCareerInfo(uuid) {
    return this.http.delete(`${this.apiUrl}/student/career/information/${uuid}`, this.httpOptions);
  }

  // added new method to call student password reset 14122022 
  studentPasswordReset(obj: { email: string; phone_number: string; }) {
    return this.http.post(`${this.apiUrl}/userpool/password/reset`, obj, this.httpOptions);
  }



}


export const MOBILEVIEW = "MOBILE";
export const WEBVIEW = "WEB";
